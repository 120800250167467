<template>
    <div>
        <h4 class="primary darken-2 white--text  py-1 text-xs-center">Contribute into: {{ njangi_group_name }}</h4>
        <v-divider></v-divider>

        <v-card class="pa-1" style="" >
            <v-card-text class="pa-1" style="font-size: 12px; ">
                <p class="dark--text subheading pa-0 ma-0"><b>Contributions:</b> <span> {{ contributed_amount}}/{{ expected_amount }} F CFA</span></p>
                <p class="dark--text caption pa-0 ma-0 text-xs-center"><span> <b>Note:</b> Contributions here is by <b>MTN Mobile Money</b> <b> or Orange Money</b> to <b>Name:</b> <u> "Petty Cash SAS"</u></span></p>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card color="white pa-3 " style="border-radius:" >
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <input type="hidden" v-model="payment_ref" ref="payment_ref" id="payment_ref">
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                            class="pa-0 mt-2"
                                id="contribution_amount" ref="contribution_amount" v-model="contribution_amount"
                                label="Amount to Contribute (F CFA)"
                                :rules="[v => !!v || 'Your amount to Contribute is required']"
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); contribution_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-phone fa-2x "> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <input @click="clearPhoneField()" id="phone" required type="tel" value="Enter Phone Number" ref="phone_number" v-model="phone_number" name="phone" class="py-2 my-2" style="width: 100%!important; border-bottom: 1px grey solid" />
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); save_contact_help = true; getHelpMessage()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex xs12 class="pt-3">
                            <h3><i class="teal--text fas fa-wrench"> </i> Select Transactor Operator</h3>                       
                        </v-flex>
                        <v-flex xs6 sm3 md4 offset-sm1 class="pt-2" @click="selectMoMo()">
                            <v-img
                                :src="require('@/assets/mtn-mobile-money-logo.png')"
                                style="cursor: pointer" contain width="100"
                            ></v-img>
                            <v-checkbox v-model="momo_choice" :checked="momo_choice" label="MTN MoMo" hide-details class="ma-1" id="selected_momo">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs6 sm3 class="pt-2" @click="selectOM()">
                            <v-img
                                :src="require('@/assets/om.png')"
                                style="cursor: pointer" contain width="70"
                            ></v-img>
                            <v-checkbox v-model="om_choice" :checked="om_choice" label="OM" hide-details class="ma-1" id="selected_om">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm4 class="pt-4 mt-3" @click="selectPettyCash()">
                            <v-img
                                :src="require('@/assets/logo-2.png')"
                                style="cursor: pointer" contain width="200"
                            ></v-img>
                            <v-checkbox v-model="pettycash_choice" :checked="pettycash_choice" label="Petty Cash" hide-details class="ma-1" id="selected_petty_cash">
                            </v-checkbox>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class=" text-xs-right">
                <v-btn class="white--text" @click="changeTelDial()" small round color="teal darken-2">
                    <span> Contribute</span>
                </v-btn>
            </div>
            <v-dialog width="500px" v-model="Dialog"> 
                <v-card> 
                    <v-card-title class="headline primary darken-2 white--text" primary-title>Confirm Deposit</v-card-title> 
                    <v-card-text class="pa-4">
                        <p class=" mb-0">By clicking <b class="teal--text darken-2">Confirm</b>, a deposit transaction of <b>{{ contribution_amount }} F CFA</b> will be triggered by you through <b>{{ transaction_operator }}</b> from the phone number <b>{{ phone_number }}</b></p>
                        <div v-if="transaction_operator === 'MTN Mobile Money' || transaction_operator === 'Orange Money'">
                            <p class=" mb-0">If you are facing difficulties, send manually through the code below:</p>
                            <p class="pl-2 mb-0 pt-1 red--text"> - <b>{{ transfer_code }}</b></p>
                            <p class="pl-2 mb-0 pt-1 " v-if="transaction_operator === 'MTN Mobile Money'"> - Choose reference 1</p>
                            <p class="pl-2 mb-0 pt-1 " v-if="transaction_operator === 'Orange Money'"> - Input transaction amount</p>
                            <p class="pl-2 mb-0 pt-1 "> - Click <b class="teal--text darken-2">Confirm</b> below</p>
                        </div>
                    </v-card-text>

                    <v-card-actions class="px-3"> 
                        <v-btn color="teal" flat @click="Dialog = false" > Cancel </v-btn> 
                        <v-spacer></v-spacer> 
                        <a id="dial_transaction" >
                            <v-btn color="teal darken-2" class="white--text" @click="contributeMoneyNjangi()" > Confirm </v-btn> 
                        </a>
                    </v-card-actions> 
                </v-card> 
            </v-dialog> 
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                njangi_id: "",
                njangi_group_name: "",
                contribution_amount: "",
                no_contributed: "",
                no_members: "",
                contributed_amount: "",
                expected_amount: "",
                current_collector: "",

                momo_choice: false,
                om_choice: false,
                pettycash_choice: false,

                Dialog: false,
                text: "The transaction will be updated to your contribution after it is verified",
                contribution_amount_help: false,
                save_contact_help: false,
                save_transaction_code_help: false,
                save_operator_help: false,
                phoneInput: [],
                operators: [],

                contribution_amount: "",
                transfer_code: "",
                payment_ref: "",
                phone_number: "Enter Phone Number",
                transaction_operator: "",
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getNjangiGroupSetting();
            this.getOperators();
            this.initialiseIntel()
            this.getNjangiContributionRef();
            document.title = "PettyCash | Contribute into Njangi"
            this.$store.commit('setCloseURL', "/njangi/groups/detail/"+this.$route.params.group_id)
        },

        methods:{
            clearPhoneField(){
                let phone_number = this.phoneInput.getNumber()
                console.log(phone_number)
                if(phone_number === "Enter Phone Number"){
                    this.phone_number = ""
                }

            },

            selectMoMo(){
                this.momo_choice = true
                this.om_choice = false
                this.pettycash_choice = false
                this.transaction_operator = "MTN Mobile Money"
            },

            selectOM(){
                this. momo_choice = false
                this.om_choice = true
                this.pettycash_choice = false
                this.transaction_operator = "Orange Money"
            },

            selectPettyCash(){
                this.momo_choice = false
                this.om_choice = false
                this.pettycash_choice = false
                this.transaction_operator = "Top Up Station"

                this.$store.commit('setSnackBarMessage', "This option is currently unavaible")
                this.$store.commit('setSnackBarColor', "teal darken-4")
                this.$store.commit('activateSnackBar', true)
            },

            initialiseIntel(){
                function getIp(callback) {
                    fetch('https://ipinfo.io/json?token=fa5e74d3e65812', { headers: { 'Accept': 'application/json' }})
                    .then((resp) => resp.json())
                    .catch(() => {
                        return {
                            country: 'cm',
                        };
                    })
                    .then((resp) => callback(resp.country));
                }

                const phoneInputField = document.querySelector("#phone");
                this.phoneInput = window.intlTelInput(phoneInputField, {
                    initialCountry: "cm",
                    geoIpLookup: getIp,
                    preferredCountries: ["cm", "us", "uk", "ci", "cg", "cf", "td", "ng", "gq", "ga"],
                    utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                });
            },

            async getNjangiGroupSetting(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.group_id  // get group id from url and send request to server
            
                await axios
                    .get('/api/v1/njangi/get/user/group/setting/detail/'+group_id+'/')
                    .then(response => {
                        this.njangi_id = response.data[1].njangi_group_id
                        this.njangi_group_name = response.data[1].njangi_group_name
                        this.contribution_amount = response.data[1].contribution_amount
                        this.no_contributed = response.data[1].get_no_contribution_members
                        this.no_members = response.data[1].get_no_members
                        this.contributed_amount = response.data[1].contributed_amount
                        this.expected_amount = response.data[1].expected_amount
                        this.current_collector = response.data[1].get_current_collector
                        this.collection_activated = response.data[1].collection_activated
                        if (!this.collection_activated){

                            this.$store.commit('setSnackBarMessage', "Contributions in this group is still to be activated.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                            
                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            setTimeout(function(){
                                navigation.push('/njangi/groups/detail/'+group_id+'/')  // redirect back to campaign
                                store.commit('setSnackBarTimeOut', 1000)  // set time out for snack bar to 4 seconds and remember too reset after 
                            }, 1000)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getNjangiContributionRef(){
                this.$store.commit('setIsLoading', true)
                
                const group_id = this.$route.params.group_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/njangi/create/user/contribute/group/payment_ref/'+group_id+'/')
                    .then(response => {
                        this.payment_ref = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getOperators(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/operators/info/')
                    .then(response => {
                        this.operators = response.data  // get the data and fill into operators
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            changeTelDial(){
                var href_tel = ""
                if(!this.contribution_amount || this.contribution_amount === "" || String(Number(this.contribution_amount)) === "NaN"){
                    if(this.contribution_amount === ""){
                        this.$store.commit('setSnackBarMessage', "Please input the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                    else{
                        this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                    document.getElementById('dial_transaction').setAttribute('href', href_tel)
                    this.$refs.contribution_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.transaction_operator === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the operator which you will send the money.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if(!this.phoneInput.isValidNumber()){
                    document.getElementById('dial_transaction').setAttribute('href', href_tel)
                    this.$store.commit('setSnackBarMessage', "Please input a valid phone number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                }
                else if(this.pettycash_choice){
                    this.Dialog = false

                    this.$store.commit('setSnackBarMessage', "Please choose MOMO or OM, this option is currently unavaible")
                    this.$store.commit('setSnackBarColor', "teal darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if(this.transaction_operator === "MTN Mobile Money" && this.contribution_amount != "" && this.phoneInput.isValidNumber()){
                    this.phone_number = this.phoneInput.getNumber()
                    this.Dialog = true
                    this.transfer_code = "*126*4*"+this.$store.state.MerchantCodeMTN+"*"+this.contribution_amount+"#"
                    href_tel = "tel:*126*4*"+this.$store.state.MerchantCodeMTN+"*"+this.contribution_amount+"%23 "
                    document.getElementById('dial_transaction').setAttribute('href', href_tel)
                    document.getElementById('dial_transaction').setAttribute('target', "_blank")
                }
                else if(this.transaction_operator === "Orange Money" && this.contribution_amount != "" && this.phoneInput.isValidNumber()){
                    this.phone_number = this.phoneInput.getNumber()
                    this.Dialog = true
                    this.transfer_code = "#150*47*"+this.$store.state.MerchantCodeOrange+"*"+this.contribution_amount+"#"
                    href_tel = "tel:%23 150*47*"+this.$store.state.MerchantCodeOrange+"*"+this.contribution_amount+"%23 "
                    document.getElementById('dial_transaction').setAttribute('href', href_tel)
                    document.getElementById('dial_transaction').setAttribute('target', "_blank")
                }
                else if(this.transaction_operator === "Top Up Station" && this.contribution_amount != "" && this.phoneInput.isValidNumber()){
                    this.phone_number = this.phoneInput.getNumber()
                    this.Dialog = true
                }
            },

            async contributeMoneyNjangi(){

                if(this.contributed_amount === "" || String(Number(this.contributed_amount)) === "NaN"){
                    if(this.contributed_amount === ""){
                        this.$store.commit('setSnackBarMessage', "Please input the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.contributed_amount.focus()  // this causes a an auto fucos to the element
                    }
                    else{
                        this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.contributed_amount.focus()  // this causes a an auto fucos to the element
                    }
                }
                else if(!this.phoneInput.isValidNumber()){
                    this.$store.commit('setSnackBarMessage', "Phone number is invalid. Please input a valid number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                }
                else if(this.transaction_operator === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the operator which you will send the money.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if(this.payment_ref === ""){
                    this.$store.commit('setSnackBarMessage', "Please an error occured with transaction, please try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('payment_ref', this.payment_ref)
                    formData.append('contribution_amount', this.contribution_amount)
                    formData.append('phone_number', this.phone_number)
                    formData.append('transaction_operator', this.transaction_operator)
    
                    const group_id = this.$route.params.group_id  // get campaign id from url and send request to server
                    await axios
                        .post('/api/v1/njangi/contribute/group/'+group_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    navigation.push('/njangi/groups/detail/'+group_id+'/')  // redirect back to campaign
                                    store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 4 seconds and remember too reset after 
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                    this.Dialog = false
                }

            },

            getHelpMessage(){
                if (this.contribution_amount_help){
                    this.contribution_amount_help = false
                    this.$store.commit('setDialogTitle', "Save Amount")
                    this.$store.commit('setDialogMessage', "You are required to input your transaction amount.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_contact_help){
                    this.save_contact_help = false
                    this.$store.commit('setDialogTitle', "Phone Contact")
                    this.$store.commit('setDialogMessage', "You are required to input the phone number used for the transaction.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_transaction_code_help){
                    this.save_transaction_code_help = false
                    this.$store.commit('setDialogTitle', "Transaction ID")
                    this.$store.commit('setDialogMessage', "Please input the transaction ID or Code proving your transaction here.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_operator_help){
                    this.save_operator_help = false
                    this.$store.commit('setDialogTitle', "Transaction Operator")
                    this.$store.commit('setDialogMessage', "Choose the operator, either MTN, Orange, Express Union, Western Union, Bank transactions etc. If its the Bank transaction, give account number here and account name, codes etc.")
                    this.$store.commit('activateDialog', true)
                }
            },

        },

    }
</script>
